import { scrollTo } from '../core/helper.js'
/* global ga */

const showSuccessElement = () => {
  const leadFormElement = document.querySelector('#lead-form')
  const successElement = document.querySelector('.cp-lead-form-success')
  const leadErrorElement = document.querySelector('.cp-lead-form-error')

  if (!leadFormElement || !successElement) {
    console.warn('LeadForm or SuccessElement not Found.')
  }

  leadFormElement.classList.add('hidden')
  leadErrorElement.classList.add('hidden')
  successElement.classList.remove('hidden')
  scrollTo('.cp-lead-form-success')
}

const showErrorElement = () => {
  const leadErrorElement = document.querySelector('.cp-lead-form-error')

  leadErrorElement.classList.remove('hidden')
}

const setSuccessPageValues = (formData) => {
  document.querySelector('.firstname-placeholder').innerText = ' ' + formData.firstName.trim()
}

// Tracking
export const getGaFormData = () => {
  const trackingData = {
    gaClientId: null,
    gaUserId: null,
    gaTrackId: null
  }

  trackingData.gaClientId = window.gaTracking?.clientId || ''
  trackingData.gaUserId = window.gaTracking?.clientId || ''
  trackingData.gaTrackId = window.gaTracking?.trackingId || ''

  return trackingData
}

const showLeadformChatbot = () => {
  const chatbotElement = document.querySelector('.cp-lead-form-chatbot')
  chatbotElement.classList.remove('hidden')

  const event = new CustomEvent('load_chatbot')
  window.dispatchEvent(event)

  window.syntea.open_chat()

  window.dataLayer.push({
    event: 'customPush',
    eventData: {
      'data-t4-eventname': 'syntea',
      'data-t4-type': 'open_automatically',
      'data-t4-position': '',
      'data-t4-label': '',
      'data-t4-formstatus': ''
    }
  })

  window.dataLayer.push({
    event: 'customEvent',
    eventData: {
      category: 'Syntea',
      action: 'open_automatically',
      label: ''
    }
  })
}

/**
 *
 *
 * @param action
 * @param state
 */
const showSuccessPageSubscription = (action, state) => {
  if (action.type === 'settings/updateField' && action.payload?.name === 'submitSuccess') {
    if (action.payload?.value === true) {
      showSuccessElement()

      const formData = state?.formData?.formData

      setSuccessPageValues(formData)
      showLeadformChatbot()
    }

    if (action.payload?.value === false) {
      showErrorElement()
    }
  }
}

const addToFormData = (store) => {
  setTimeout(() => {
    if (window.innerWidth < 1024) {
      scrollTo('#lead-form')
    }
    const trackingData = getGaFormData()
    store.dispatch('formData/update', trackingData)

    const formdata = store.getters['formData/all']
    if (!formdata.businessUnit && document.body.dataset.businessUnit) {
      const defaults = { businessUnit: document.body.dataset.businessUnit }
      store.dispatch('formData/update', defaults)
    }
    const params = new URLSearchParams(window.location.search)
    store.dispatch('formData/update', { isKoop: params.get('utm_medium') === 'koop' })
  }, 1000)
}

const submitMutation = (e) => {
  if (e.type === 'formData/UPDATE' && e.payload?.submit_status === 'ok' && e.payload?.key !== undefined) {
    const items = []
    e.payload.programs.forEach((program) => {
      items.push({
        item_id: program['id'] + '-sg',
        item_name: program['title'],
        currency: 'EUR',
        item_brand: e.payload.market,
        item_category: program['degree'],
        item_category2: program['subCluster'],
        item_category3: e.payload.location ? 'On Campus' : 'Online',
        item_category4: e.payload.location ?? '',
        item_list_name: program['subCluster'],
        location_id: e.payload.location_id,
        price: 0.0,
        quantity: 1
      })
    })
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        currency: 'EUR',
        value: 0,
        items: items
      },
      eventData: {
        'data-t4-eventname': 'select_item',
        'data-t4-formkey': e.payload.key,
        'data-t4-type': 'ecommerce',
        'data-t4-label': 'online',
        'data-t4-opt_in_email': e.payload.optInAll || e.payload.optIn,
        'data-t4-opt_in_phone': false,
        'data-t4-opt_in_sms': e.payload.optInAll ?? false,
        'data-t4-opt_in_whatsapp': e.payload.optInAll ?? false,
        'data-t4-englishlevel': e.payload.englishLevel ?? false,
        'data-t4-paymentsource': e.payload.saFinancingStudies ?? '',
        'data-t4-funnelstep': e.payload.saCurrentUniversitySelectionProcess ?? 'false',
        'data-t4-salary': e.payload.saCurrentSalary ?? '',
        'data-t4-computeraccess': e.payload.saAccessToLaptop ?? ''
      }
    })
  }
}

window.customJS = {
  onBeforeMount: [],
  onMounted: [addToFormData],
  subscribeAction: [showSuccessPageSubscription],
  subscribeMutation: [submitMutation],
  onBeforeSubmit: []
}
